import { Link } from 'react-router-dom';

import { Card, CardMedia, Button } from '@mui/material';
import './VolyaCard.scss';

interface VolyaCardProps {
  image: string;
  title: string;
  link: string;
}

export function VolyaCard({ image, title, link }: VolyaCardProps) {
  return (
    <Card className="volya-card">
      <CardMedia component="img" image={image} alt={title} className="volya-card-img" />
      <Button component={Link} to={link} className="volya-card-button" fullWidth>
        {title}
      </Button>
    </Card>
  );
}
