import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { useEffect } from 'react';

import { events } from './fixtures';
import { JoinUs } from './JoinUs';
import './Events.scss';

export type Event = {
  name: string;
  img: string;
  date: string;
  address: string;
  description: string;
};

export const useEvents = () => {
  return events;
};

export function Events() {
  const { t } = useTranslation(['translation', 'fixtures']);
  const events = useEvents();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="events">
      <h1>{t('events.title')}</h1>
      <div className="description">{t('events.description')}</div>
      {/* <div><iframe width="400" height="215" src="https://lanka.volya-asso.fr/index.php/apps/calendar/embed/Db5gZZdmL4BsQGSB"></iframe></div> */}
      <ul>
        {events.map((e, index) => (
          <li key={index}>
            <Card>
              <Card.Img src={`${process.env.PUBLIC_URL}/events/${e.img}`} alt={t(e.nameKey, { ns: 'fixtures' })} />
              <Card.Body>
                <Card.Subtitle>{t(e.dateKey, { ns: 'fixtures' })}</Card.Subtitle>
                <Card.Title>{t(e.nameKey, { ns: 'fixtures' })}</Card.Title>
                <Card.Subtitle>{t(e.addressKey, { ns: 'fixtures' })}</Card.Subtitle>
                <Card.Text>{t(e.descriptionKey, { ns: 'fixtures' })}</Card.Text>
              </Card.Body>
            </Card>
          </li>
        ))}
      </ul>
      <JoinUs />
    </div>
  );
}
