import { initReactI18next } from 'react-i18next';

import i18next from 'i18next';
import HttpApi from 'i18next-http-backend';

i18next
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    lng: 'fr',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    // debug: process.env.NODE_ENV === 'development',
    load: 'all',
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    ns: ['translation', 'fixtures'], // Namespace, matching the file name (e.g., translation.json)
    defaultNS: 'translation',
    supportedLngs: ['fr', 'uk', 'en'],
  });

export default i18next;
