import { useTranslation } from 'react-i18next';

import Tooltip from '@mui/material/Tooltip';

import { ExternalLink } from './ExternalLinksProps'; // A generic component for external links
import { partners } from '../fixtures';
import './PartnersVolya.scss';

export function PartnersVolya() {
  const { t } = useTranslation(['translation', 'fixtures']);

  return (
    <div className="partners-volya-section">
      <h2 className="partners-volya-title">{t('home.partners')}</h2>
      <div className="partners-volya-grid">
        {partners.map((partner) => (
          <Tooltip key={partner.nameKey} title={t(partner.nameKey, { ns: 'fixtures' })} arrow>
            <div className="partners-volya-card">
              <ExternalLink href={partner.url || '#'}>
                <div className="partners-volya-image">
                  <img
                    src={`${process.env.PUBLIC_URL}/partners/${partner.img}`}
                    alt={t(partner.nameKey, { ns: 'fixtures' })}
                  />
                </div>
              </ExternalLink>{' '}
            </div>
          </Tooltip>
        ))}
      </div>
    </div>
  );
}
