import { useTranslation, Trans } from 'react-i18next';

import { Container, Typography, Box, Stack } from '@mui/material';

import inclusion from '../assets/our-goals/inclusion.png';
import fle from '../assets/our-goals/cours-fle.png';
import childrenSupport from '../assets/our-goals/children-sadyba.png';
import sadyba from '../assets/our-goals/sadyba-incl.png';
import culturalActivities from '../assets/our-goals/sport-cholet.jpg';
import adminSupport from '../assets/our-goals/nantes.jpg';
import localPartnerships from '../assets/our-goals/croq-vacances.png';
import inclusionPilier from '../assets/our-goals/together.jpg';
import dotGroupSVG from '../assets/dot-group.svg';
import { BenevoleCard, DonateCard, EmploymentCard, KalynaCard } from '../PaddedComponents';
import { HUMANITAIRE_LINK, SENSIBILISATION_LINK } from '../App';
import { JoinUs } from '../JoinUs';
import { BottomNav } from './FactPagesNav';
import './Inclusion.scss';

export function Inclusion() {
  const { t } = useTranslation();

  return (
    <Container className="inclusion-page">
      {/* Page Title */}
      <Typography variant="h2" gutterBottom className="page-title">
        {t('inclusion.title')}
      </Typography>

      {/* Introduction Section */}
      <Box className="introduction-section" textAlign="center">
        <Typography className="intro-title" variant="h4" gutterBottom>
          {t('inclusion.introductionTitle')}
        </Typography>
        <Typography variant="body1" className="description">
          <Trans i18nKey="inclusion.introductionDescription" components={{ strong: <strong /> }} />
        </Typography>
        <Box className="intro-image-container">
          <img src={inclusion} alt={t('inclusion.heroesOfAide')} className="intro-image" />
        </Box>
      </Box>

      {/* Initiatives Section */}
      <Typography variant="h4" gutterBottom align="center">
        {t('inclusion.initiativesTitle')}
      </Typography>

      {/* FLE */}
      <Box className="description">
        <Box className="text-content">
          <Typography variant="h5" className="title" gutterBottom>
            {t('inclusion.sections.fleCourses.title')}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {t('inclusion.sections.fleCourses.subtitle')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.fleCourses.description')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.fleCourses.extendedDescription')}
          </Typography>
        </Box>
        <Box className="image-container">
          <img src={fle} alt={t('Inclusion')} />
        </Box>
      </Box>

      {/* childrenSupport */}
      <Box className="description-inverse">
        <Box className="image-container">
          <img src={childrenSupport} alt={t('Inclusion')} />
        </Box>
        <Box className="text-content">
          <Typography variant="h5" className="subttitleitle" gutterBottom>
            {t('inclusion.sections.childrenSupport.title')}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {t('inclusion.sections.childrenSupport.subtitle')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.childrenSupport.description')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.childrenSupport.extendedDescription')}
          </Typography>
        </Box>
      </Box>

      {/* sadyba */}
      <Box className="description">
        <Box className="text-content">
          <Typography variant="h5" className="title" gutterBottom>
            {t('inclusion.sections.sadyba.title')}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {t('inclusion.sections.sadyba.subtitle')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.sadyba.description')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.sadyba.extendedDescription')}
          </Typography>
        </Box>
        <Box className="image-container">
          <img src={sadyba} alt={t('Inclusion')} />
        </Box>
      </Box>

      {/* culturalActivities */}
      <Box className="description-inverse">
        <Box className="image-container">
          <img src={culturalActivities} alt={t('Inclusion')} />
        </Box>
        <Box className="text-content">
          <Typography variant="h5" className="title" gutterBottom>
            {t('inclusion.sections.culturalActivities.title')}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {t('inclusion.sections.culturalActivities.subtitle')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.culturalActivities.description')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.culturalActivities.extendedDescription')}
          </Typography>
        </Box>
      </Box>

      {/* adminSupport */}
      <Box className="description">
        <Box className="text-content">
          <Typography variant="h5" className="title" gutterBottom>
            {t('inclusion.sections.adminSupport.title')}
          </Typography>
          <Typography variant="body1" className="title">
            {t('inclusion.sections.adminSupport.subtitle')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.adminSupport.description')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.adminSupport.extendedDescription')}
          </Typography>
        </Box>
        <Box className="image-container">
          <img src={adminSupport} alt={t('Inclusion')} />
        </Box>
      </Box>

      {/* localPartnerships */}
      <Box className="description-inverse">
        <Box className="image-container">
          <img src={localPartnerships} alt={t('Inclusion')} />
        </Box>
        <Box className="text-content">
          <Typography variant="h5" className="title" gutterBottom>
            {t('inclusion.sections.localPartnerships.title')}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {t('inclusion.sections.localPartnerships.subtitle')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.localPartnerships.description')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.localPartnerships.extendedDescription')}
          </Typography>
        </Box>
      </Box>

      {/* inclusionPilier */}
      <Box className="description">
        <Box className="text-content">
          <Typography variant="h5" className="title" gutterBottom>
            {t('inclusion.sections.inclusionPilier.title')}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {t('inclusion.sections.inclusionPilier.subtitle')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.inclusionPilier.description')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('inclusion.sections.inclusionPilier.extendedDescription')}
          </Typography>
        </Box>
        <Box className="image-container">
          <img src={inclusionPilier} alt={t('Inclusion')} />
        </Box>
      </Box>

      <Stack className="stats-section">
        {/* Card 1: Disneyland */}
        <Box className="info-box">
          <Box className="info-content">
            <Typography variant="h4" className="info-title">
              {t('inclusion.stats.disneylandTitle')}
            </Typography>
            <Typography variant="body2">
              <Trans i18nKey="inclusion.stats.disneyland" components={{ strong: <strong /> }} />
            </Typography>
          </Box>
        </Box>

        {/* Card 2: Children */}
        <Box className="info-box">
          <Box className="info-content">
            <Typography variant="h4" className="info-title">
              {t('inclusion.stats.childrenTitle')}
            </Typography>
            <Typography variant="body2">
              <Trans i18nKey="inclusion.stats.children" components={{ strong: <strong /> }} />
            </Typography>
          </Box>
        </Box>

        {/* Card 3: FLE Courses */}
        <Box className="info-box">
          <Box className="info-content">
            <Typography variant="h4" className="info-title">
              {t('inclusion.stats.fleCoursesTitle')}
            </Typography>
            <Typography variant="body2">
              <Trans i18nKey="inclusion.stats.fleCourses" components={{ strong: <strong /> }} />
            </Typography>
          </Box>
        </Box>

        {/* Card 4: FLE CCI */}
        <Box className="info-box">
          <Box className="info-content">
            <Typography variant="h4" className="info-title">
              {t('inclusion.stats.fleCciTitle')}
            </Typography>
            <Typography variant="body2">
              <Trans i18nKey="inclusion.stats.fleCci" components={{ strong: <strong /> }} />
            </Typography>
          </Box>
        </Box>

        {/* Card 5: Shakhtar */}
        <Box className="info-box">
          <Box className="info-content">
            <Typography variant="h4" className="info-title">
              {t('inclusion.stats.shakhtarTitle')}
            </Typography>
            <Typography variant="body2">
              <Trans i18nKey="inclusion.stats.shakhtar" components={{ strong: <strong /> }} />
            </Typography>
          </Box>
        </Box>

        {/* Card 6: Concert */}
        <Box className="info-box">
          <Box className="info-content">
            <Typography variant="h4" className="info-title">
              {t('inclusion.stats.concertTitle')}
            </Typography>
            <Typography variant="body2">
              <Trans i18nKey="inclusion.stats.concert" components={{ strong: <strong /> }} />
            </Typography>
          </Box>
        </Box>
      </Stack>

      {/* Visual Separator */}
      <Box className="centered-image">
        <img className="img-in-the-middle" src={dotGroupSVG} alt="Visual Separator" />
      </Box>

      <Box className="padded-cards">
        <KalynaCard />
        <EmploymentCard />
        <BenevoleCard />
        <DonateCard />
      </Box>

      <JoinUs />
      <BottomNav link1={HUMANITAIRE_LINK} link2={SENSIBILISATION_LINK} />
    </Container>
  );
}
