import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faSquareFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Box, Typography } from '@mui/material';

import './ContactUs.scss';
import volunteer from './assets/our-goals/benevole2.png';
import calendar from './assets/calendar.png';
import { ExternalLink } from './components/ExternalLinksProps';
import { EXTERNAL_LINKS } from './constants/links';

export function ContactUs() {
  const { t } = useTranslation();
  return (
    <div className="contact-us">
      {/* Use the translation key for the title */}
      <Typography variant="h2" gutterBottom className="page-title">
        {t('contactUs.supportUkraineTitle')}
      </Typography>
      <Typography variant="h2" gutterBottom className="page-title">
        {t('contactUs.supportUkraineTitle2')}
      </Typography>

      {/* Section Description */}
      <div className="description">{t('contactUs.description')}</div>
      <div className="description">{t('contactUs.description2')}</div>

      {/* Social Media and Contact Information */}
      <div className="right-column">
        <span>{t('contactUs.socialMedia')}</span>

        <div className="socials">
          <ExternalLink href={EXTERNAL_LINKS.INSTAGRAM}>
            <FontAwesomeIcon icon={faInstagram} />
          </ExternalLink>
          <ExternalLink href={EXTERNAL_LINKS.LINKEDIN}>
            <FontAwesomeIcon icon={faLinkedin} />
          </ExternalLink>
          <ExternalLink href={EXTERNAL_LINKS.FACEBOOK}>
            <FontAwesomeIcon icon={faSquareFacebook} />
          </ExternalLink>
          <ExternalLink href={EXTERNAL_LINKS.TWITTER}>
            <FontAwesomeIcon icon={faTwitter} />
          </ExternalLink>
        </div>

        {/* Link to Linktree */}
        <div className="linktree-link">
          <ExternalLink href={EXTERNAL_LINKS.LINKTREE}>{t('contactUs.allInfoLinktree')}</ExternalLink>
        </div>

        {/* Contact */}
        <span>{t('contactUs.contactUsEmail')}</span>
        <span className="email">{t('contactUs.mail')}</span>
        <Box className="centered-image">
          <img className="img-calendar" src={calendar} alt="Visual Separator" />
        </Box>
        <span>{t('contactUs.volunteerCallToAction')}</span>

        <Box className="centered-image">
          <img className="img-in-the-middle" src={volunteer} alt="Visual Separator" />
        </Box>
        <Typography variant="h5" className="description">
          {t('contactUs.slavaUkraini')}
        </Typography>
      </div>
    </div>
  );
}
