import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Container, Typography, Button, Box, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHandsHelping,
  faGraduationCap,
  faLink,
  faUsers,
  faCalendarCheck,
  faGlobe,
  faBriefcaseMedical,
} from '@fortawesome/free-solid-svg-icons';

import aboutUs from './assets/about-us.png';
import socialObjects from './assets/social-objects.png';
import pillars from './assets/pillars.png';
import { members, membersAvatars } from './fixtures';
import { JoinUs } from './JoinUs';
import { DonateCard } from './PaddedComponents';
import { HOME_LINK, SENSIBILISATION_LINK, HUMANITAIRE_LINK, INCLUSION_LINK } from './App';
import { BottomNav } from './FactPages/FactPagesNav';

import './AboutUs.scss';

const socialObjectIcons = [
  faHandsHelping, // Icon for socialObject1
  faGraduationCap, // Icon for socialObject2
  faLink, // Icon for socialObject3
  faUsers, // Icon for socialObject4
  faCalendarCheck, // Icon for socialObject5
  faGlobe, // Icon for socialObject6
  faBriefcaseMedical, // Icon for socialObject7
];

export function AboutUs() {
  const { t, i18n } = useTranslation(['translation', 'fixtures']);

  // Helper function to retrieve member images
  const getPhoto = (e, i) => {
    return membersAvatars[i] ? (
      <img
        src={`${process.env.PUBLIC_URL}/avatars/${membersAvatars[i]}.png`}
        alt={t(e.nameKey, { ns: 'fixtures' })}
        className="avatar-image"
      />
    ) : (
      <img src="" alt="member" />
    );
  };

  return (
    <Container maxWidth="lg" className="about-us">
      {/* Main Title */}
      <Typography variant="h3" align="center" gutterBottom className="about-us-title">
        {t('aboutUs.title')}
      </Typography>

      {/* Mission Section */}
      <Box className="section">
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} className="description" alignItems="center">
          <Box className="text-section">
            <Typography variant="h4" gutterBottom>
              {t('aboutUs.missionTitle')}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {t('aboutUs.description1')}
            </Typography>
            <Typography variant="body1" className="spaced-description">
              {t('aboutUs.description2')}
            </Typography>
          </Box>
          <Box className="image-section">
            <img src={aboutUs} alt="Mission section" className="section-image" />
          </Box>
        </Stack>
      </Box>

      {/* Nos Valeurs Section */}
      <Box className="values-section">
        <Typography variant="h4" gutterBottom className="values-title">
          {t('aboutUs.valuesTitle')}
        </Typography>
        <Typography variant="body1" className="values-text">
          <Trans i18nKey="aboutUs.valuesDescription" components={{ strong: <strong /> }} />
        </Typography>
      </Box>

      {/* Axes Section */}
      <Box className="pillars-image">
        <img src={pillars} alt={t('Pilliers Volya')} />
      </Box>
      <Stack className="axes-section">
        <Box className="info-box">
          <Box className="info-content">
            <Typography variant="h4" className="info-title">
              {t('aboutUs.inclusion')}
            </Typography>
            <Typography variant="h5" className="sub-title">
              {t('aboutUs.inclusion2')}
            </Typography>
            <Typography variant="body2">{t('aboutUs.inclusionDescription')}</Typography>
          </Box>
          <Box className="info-button-wrapper">
            <Button variant="contained" component={Link} to={INCLUSION_LINK} className="btn-axes">
              {t('aboutUs.learnMoreButton')}
            </Button>
          </Box>
        </Box>

        <Box className="info-box">
          <Box className="info-content">
            <Typography variant="h4" className="info-title">
              {t('aboutUs.humanitaire')}
            </Typography>
            <div className="divider" />
            <Typography variant="h5" className="sub-title">
              {t('aboutUs.humanitaire2')}
            </Typography>
            <Typography variant="body2">{t('aboutUs.humanitaireDescription')}</Typography>
          </Box>
          <Box className="info-button-wrapper">
            <Button variant="contained" component={Link} to={HUMANITAIRE_LINK} className="btn-axes">
              {t('aboutUs.learnMoreButton')}
            </Button>
          </Box>
        </Box>

        <Box className="info-box">
          <Box className="info-content">
            <Typography variant="h4" className="info-title">
              {t('aboutUs.sensibilisation')}
            </Typography>
            <div className="divider" />
            <Typography variant="h5" className="sub-title">
              {t('aboutUs.sensibilisation2')}
            </Typography>
            <Typography variant="body2" className="info-text">
              {t('aboutUs.sensibilisationDescription')}
            </Typography>
          </Box>
          <Box className="info-button-wrapper">
            <Button variant="contained" component={Link} to={SENSIBILISATION_LINK} className="btn-axes">
              {t('aboutUs.learnMoreButton')}
            </Button>
          </Box>
        </Box>
      </Stack>

      {/* Social Object Section */}
      <Box className="section social-objects">
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} className="social-object-wrapper">
          <Box className="social-object-text-list">
            <Typography variant="h4" align="center" gutterBottom>
              {t('aboutUs.socialObjectTitle')}
            </Typography>
            {Array.from({ length: 7 }, (_, i) => (
              <Stack key={i} direction="row" spacing={3} className="social-object-item" alignItems="center">
                <FontAwesomeIcon icon={socialObjectIcons[i]} className="social-object-icon" />
                <Typography variant="body1" className="social-object-text">
                  {t(`aboutUs.socialObject${i + 1}`)}
                </Typography>
              </Stack>
            ))}
          </Box>
        </Stack>
      </Box>

      {/* Team Section */}
      <Box className="team-section">
        <Typography variant="h4" align="center">
          {t('aboutUs.teamTitle')}
        </Typography>
        <Typography variant="body1" align="center" className="team-description">
          {t('aboutUs.teamDescription')}
        </Typography>
        <Stack className="team-list">
          {members[i18n.language].map((e, i) => (
            <Box key={e.nameKey} className="team-member">
              {getPhoto(e, i)}
              <Typography variant="h6" className="member-name">
                {t(e.nameKey, { ns: 'fixtures' })}
              </Typography>
              <Typography variant="body2">{t(e.descriptionKey, { ns: 'fixtures' })}</Typography>
            </Box>
          ))}
        </Stack>
      </Box>

      {/* Document Section */}
      <Box className="documents-content">
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={3} className="description" alignItems="center">
          {/* Image on the left */}
          <Box className="image-section">
            <img src={socialObjects} alt="Documents associatifs" className="section-image" />
          </Box>

          {/* Title and Document List on the Right */}
          <Box className="text-section">
            <Typography variant="h4" className="section-title">
              {t('aboutUs.documentsTitle')}
            </Typography>

            {/* Document Links List */}
            <Box component="ul" className="document-list">
              <Box>
                <Link to={HOME_LINK} className="document-link">
                  {t('aboutUs.document1')}
                </Link>
              </Box>
              <Box>
                <Link to={HOME_LINK} className="document-link">
                  {t('aboutUs.document2')}
                </Link>
              </Box>
              <Box>
                <Link to={HOME_LINK} className="document-link">
                  {t('aboutUs.document3')}
                </Link>
              </Box>
            </Box>
          </Box>
        </Stack>
      </Box>

      <DonateCard />
      <JoinUs />
      <BottomNav link1={HOME_LINK} link2={HUMANITAIRE_LINK} />
    </Container>
  );
}
