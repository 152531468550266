import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

import { Box, Typography } from '@mui/material';

import './FaireDon.scss';
import { JoinUs } from './JoinUs';
import dotGroupSVG from './assets/dot-group.svg';
import ua from './assets/flag-sun.jpg';
import { EXTERNAL_LINKS } from './constants/links';

export function FaireDon() {
  const { t } = useTranslation();
  return (
    <div>
      <div className="faire-don">
        <Typography variant="h2" gutterBottom align="center" className="page-title">
          {t('faireDon.title')}
        </Typography>
        <div className="CTA-group">
          <div className="left-column">
            <img src={dotGroupSVG} />
            <Button
              variant="primary"
              className="big-btn CTA"
              href={EXTERNAL_LINKS.DONATION_LINK}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('faireDon.fillForm')}
            </Button>
          </div>
          <div className="right-column">
            <span>{t('faireDon.sendCheck')}</span>
            <Typography variant="h6" align="center">
              {t('faireDon.address')}
            </Typography>
            <span>{t('faireDon.or')}</span>
            <span>{t('faireDon.contactUs')}</span>
            <span>{t('faireDon.learnMore')}</span>
            <Typography variant="h6" align="center" color="#f0c717">
              {t('faireDon.email')}
            </Typography>
          </div>
        </div>
        {/* How Donations are Used Section */}
        <div className="utilisation-dons">
          <Typography variant="h6" align="center">
            {t('faireDon.donUseTitle')}
          </Typography>
          <Typography variant="body1" align="center">
            {t('faireDon.donDescription')}
          </Typography>

          {/* Projects Section */}
          <h4>{t('faireDon.projectsTitle')}</h4>
          <ol>
            <li>
              <strong>{t('faireDon.project1Title')}:</strong> {t('faireDon.project1')}
            </li>
            <li>
              <strong>{t('faireDon.project2Title')}:</strong> {t('faireDon.project2')}
            </li>
            <li>
              <strong>{t('faireDon.project3Title')}:</strong> {t('faireDon.project3')}
            </li>
            <li>
              <strong>{t('faireDon.project4Title')}:</strong> {t('faireDon.project4')}
            </li>
            <li>
              <strong>{t('faireDon.project5Title')}:</strong> {t('faireDon.project5')}
            </li>
          </ol>
        </div>
      </div>
      {/* Why Help Section */}
      <div className="centered-text">
        <Box>
          <img className="img-in-the-middle" src={ua} alt="Visual Separator" />
        </Box>
        <h4>{t('faireDon.whyHelpTitle')}</h4>
        <p>{t('faireDon.whyHelp')}</p>
        <p>
          <strong>{t('faireDon.whyHelp2')}</strong>
        </p>
      </div>
      <JoinUs />
    </div>
  );
}
