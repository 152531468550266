import { Navbar, Button, Container, Nav, Image } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';

import { CONTACT_US_LINK, FAIRE_DON_LINK, KALYNANTES_LINK, ABOUT_US_LINK, HOME_LINK } from './App';

export const FR = 'fr';
export const UA = 'uk';

export function Navigation() {
  const { t, i18n } = useTranslation();
  return (
    <Navbar expand="lg" className="navbar-body">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <Image className="nav-logo" src={`${process.env.PUBLIC_URL}/logo_volya.png`} />
        </Navbar.Brand>
        <Navbar.Toggle className="nav-toggle" aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="navbar-row">
          <Nav className="volya-navbar" activeKey={window.location.pathname}>
            <Button className="switch-lang" onClick={() => i18n.changeLanguage(i18n.language === FR ? UA : FR)}>
              {t('navbar.nextLang')}
            </Button>
            <Nav.Link as={Link} to={HOME_LINK}>
              <FontAwesomeIcon icon={faHouse} />
            </Nav.Link>
            <Nav.Link as={Link} to={ABOUT_US_LINK} className="nav-link">
              {t('navbar.association')}
            </Nav.Link>
            <Nav.Link as={Link} to={KALYNANTES_LINK} className="nav-link">
              {t('navbar.kalyNantes')}
            </Nav.Link>
            <Nav.Link as={Link} to={CONTACT_US_LINK} className="nav-link">
              {t('navbar.nousJoindre')}
            </Nav.Link>
            <Nav.Link as={Link} to={FAIRE_DON_LINK} className="faire-don-btn btn">
              {t('navbar.faireDon')}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
