export const partners = [
  {
    nameKey: 'fixtures.partners.cciNantes',
    img: 'cci-nantes.png',
    url: 'https://www.paysdelaloire.cci.fr/nantes-saint-nazaire',
  },
  {
    nameKey: 'fixtures.partners.villeDeNantes',
    img: 'ville-de-nantes.png',
    url: 'https://metropole.nantes.fr/',
  },
  {
    nameKey: 'fixtures.partners.cosmopolis',
    img: 'cosmopolis.png',
    url: 'https://cosmopolis.nantes.fr/',
  },
  {
    nameKey: 'fixtures.partners.vendee',
    img: 'vendee.png',
    url: 'https://vendeukraine.wordpress.com/',
  },
  {
    nameKey: 'fixtures.partners.hope',
    img: 'hope.png',
    url: 'https://www.hopeeurope.com',
  },
  {
    nameKey: 'fixtures.partners.fondsSet',
    img: 'fonds-set.png',
    url: 'https://fondsset.fr/',
  },
  {
    nameKey: 'fixtures.partners.blancheCastille',
    img: 'blanche-de-castille.png',
    url: 'https://blanchedecastille.com/',
  },
  {
    nameKey: 'fixtures.partners.nantesMetropole',
    img: 'nantes-metropole.png',
    url: 'https://metropole.nantes.fr/',
  },
  {
    nameKey: 'fixtures.partners.cau',
    img: 'cau.png',
    url: 'https://www.facebook.com/caukraine/',
  },
  {
    nameKey: 'fixtures.partners.mcm',
    img: 'mcm.png',
    url: 'https://www.mcm44.org',
  },
  {
    nameKey: 'fixtures.partners.uaCulture',
    img: 'ua_culture.png',
  },
  {
    nameKey: 'fixtures.partners.communaute',
    img: 'communaute.png',
  },
  {
    nameKey: 'fixtures.partners.croqVacances',
    img: 'croq-vacances.png',
    url: 'https://croqvacances.org/',
  },
  {
    nameKey: 'fixtures.partners.mde',
    img: 'mde.png',
    url: 'https://www.maisoneurope-nantes.eu/',
  },
  {
    nameKey: 'fixtures.partners.cinematographe',
    img: 'cinematographe.png',
    url: 'https://www.lecinematographe.com/',
  },
  {
    nameKey: 'fixtures.partners.ripaillon',
    img: 'ripaillon.png',
    url: 'https://www.facebook.com/p/Collectif-Ripaillon-61555904589418/',
  },
  {
    nameKey: 'fixtures.partners.mict',
    img: 'mict.png',
    url: 'https://mictfilmfestival.com/',
  },
  {
    nameKey: 'fixtures.partners.unisCite',
    img: 'unis-cite.png',
    url: 'https://www.uniscite.fr/',
  },
  {
    nameKey: 'fixtures.partners.tyl',
    img: 'tyl.png',
    url: 'https://tyl.media/',
  },
  {
    nameKey: 'fixtures.partners.franceBenevolat',
    img: 'france-benevolat.png',
    url: 'https://nantes.francebenevolat.org/',
  },
  {
    nameKey: 'fixtures.partners.croixRouge',
    img: 'croix-rouge.png',
    url: 'https://www.croix-rouge.fr/',
  },
  {
    nameKey: 'fixtures.partners.ahlla',
    img: 'ahlla.png',
    url: 'https://www.ahlla.fr/',
  },
];

export const partnersKaly = [
  {
    nameKey: 'fixtures.kalyPartners.blancheCastille',
    img: 'blanche-de-castille.png',
    descriptionKey: 'fixtures.kalyPartners.blancheCastilleDescription',
  },
  {
    nameKey: 'fixtures.kalyPartners.fondsSet',
    img: 'fonds-set.png',
    descriptionKey: 'fixtures.kalyPartners.fondsSetDescription',
  },
];

export const events = [
  {
    nameKey: 'fixtures.events.event1.name',
    img: 'dummy-event.png',
    dateKey: 'fixtures.events.event1.date',
    addressKey: 'fixtures.events.event1.address',
    descriptionKey: 'fixtures.events.event1.description',
  },
  {
    nameKey: 'fixtures.events.event2.name',
    img: 'dummy-event.png',
    dateKey: 'fixtures.events.event2.date',
    addressKey: 'fixtures.events.event2.address',
    descriptionKey: 'fixtures.events.event2.description',
  },
  {
    nameKey: 'fixtures.events.event3.name',
    img: 'dummy-event.png',
    dateKey: 'fixtures.events.event3.date',
    addressKey: 'fixtures.events.event3.address',
    descriptionKey: 'fixtures.events.event3.description',
  },
  {
    nameKey: 'fixtures.events.event4.name',
    img: 'dummy-event.png',
    dateKey: 'fixtures.events.event4.date',
    addressKey: 'fixtures.events.event4.address',
    descriptionKey: 'fixtures.events.event4.description',
  },
];

export const membersAvatars = ['nataliya', 'youcef', 'alona', 'daria', 'killian', 'taisiia'];

export const members = {
  fr: [
    { nameKey: 'fixtures.members.nataliya.name', descriptionKey: 'fixtures.members.nataliya.description' },
    { nameKey: 'fixtures.members.youcef.name', descriptionKey: 'fixtures.members.youcef.description' },
    { nameKey: 'fixtures.members.alona.name', descriptionKey: 'fixtures.members.alona.description' },
    { nameKey: 'fixtures.members.daria.name', descriptionKey: 'fixtures.members.daria.description' },
    { nameKey: 'fixtures.members.killian.name', descriptionKey: 'fixtures.members.killian.description' },
    { nameKey: 'fixtures.members.taisiia.name', descriptionKey: 'fixtures.members.taisiia.description' },
  ],
  uk: [
    { nameKey: 'fixtures.members.nataliya.name', descriptionKey: 'fixtures.members.nataliya.description' },
    { nameKey: 'fixtures.members.youcef.name', descriptionKey: 'fixtures.members.youcef.description' },
    { nameKey: 'fixtures.members.alona.name', descriptionKey: 'fixtures.members.alona.description' },
    { nameKey: 'fixtures.members.daria.name', descriptionKey: 'fixtures.members.daria.description' },
    { nameKey: 'fixtures.members.killian.name', descriptionKey: 'fixtures.members.killian.description' },
    { nameKey: 'fixtures.members.taisiia.name', descriptionKey: 'fixtures.members.taisiia.description' },
  ],
};

export const tutorsAvatars = ['valentyna', 'maryna', 'anna', 'igor', 'tetyana', 'nataliya', 'youcef'];

export const tutors = {
  fr: [
    { nameKey: 'fixtures.tutors.valentyna.name', descriptionKey: 'fixtures.tutors.valentyna.description' },
    { nameKey: 'fixtures.tutors.maryna.name', descriptionKey: 'fixtures.tutors.maryna.description' },
    { nameKey: 'fixtures.tutors.anna.name', descriptionKey: 'fixtures.tutors.anna.description' },
    { nameKey: 'fixtures.tutors.igor.name', descriptionKey: 'fixtures.tutors.igor.description' },
    { nameKey: 'fixtures.tutors.tetyana.name', descriptionKey: 'fixtures.tutors.tetyana.description' },
    { nameKey: 'fixtures.tutors.nataliya.name', descriptionKey: 'fixtures.tutors.nataliya.description' },
    { nameKey: 'fixtures.tutors.youcef.name', descriptionKey: 'fixtures.tutors.youcef.description' },
  ],
  uk: [
    { nameKey: 'fixtures.tutors.valentyna.name', descriptionKey: 'fixtures.tutors.valentyna.description' },
    { nameKey: 'fixtures.tutors.maryna.name', descriptionKey: 'fixtures.tutors.maryna.description' },
    { nameKey: 'fixtures.tutors.anna.name', descriptionKey: 'fixtures.tutors.anna.description' },
    { nameKey: 'fixtures.tutors.igor.name', descriptionKey: 'fixtures.tutors.igor.description' },
    { nameKey: 'fixtures.tutors.tetyana.name', descriptionKey: 'fixtures.tutors.tetyana.description' },
    { nameKey: 'fixtures.tutors.nataliya.name', descriptionKey: 'fixtures.tutors.nataliya.description' },
    { nameKey: 'fixtures.tutors.youcef.name', descriptionKey: 'fixtures.tutors.youcef.description' },
  ],
};
