import { useTranslation, Trans } from 'react-i18next';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

import L, { LatLngExpression } from 'leaflet';
import { Container, Typography, Box, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcaseMedical, faUtensils, faSoap, faBolt, faHandsHelping } from '@fortawesome/free-solid-svg-icons';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';

import collectionImage from '../assets/truck.jpg';
import specialProjectsImage from '../assets/entrepot.jpg';
import localSupportImage from '../assets/local-help.jpg';
import convoi from '../assets/truck-flag.jpg';
import aid from '../assets/our-goals/aid.png';
import liste from '../assets/our-goals/liste_collecte.jpg';
import whyDon from '../assets/our-goals/volya-need.jpg';
import dotGroupSVG from '../assets/dot-group.svg';
import childrenPresents from '../assets/humaniatrian-presents.png';
import { BenevoleCard, DonateCard } from '../PaddedComponents';
import { JoinUs } from '../JoinUs';
import { BottomNav } from './FactPagesNav';
import { SENSIBILISATION_LINK, INCLUSION_LINK } from '../App';
import './AideHumanitaire.scss';
import 'leaflet/dist/leaflet.css';

export function AideHumanitaire() {
  const { t } = useTranslation();
  const statistics = t('humanitaire.communityImpact.statistics', { returnObjects: true }) as string[];
  const position: LatLngExpression = [47.19538680390028, -1.5432021838330994];

  const customIcon = new L.Icon({
    iconUrl: markerIconPng,
    iconSize: [25, 41], // Size of the icon
    iconAnchor: [12, 41], // Anchor point of the icon
    popupAnchor: [1, -34], // Popup placement
    // eslint-disable-next-line
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    shadowSize: [41, 41], // Size of the shadow
    shadowAnchor: [12, 41], // Anchor point of the shadow
  });

  return (
    <Container className="aide-humanitaire-page">
      {/* Page Title */}
      <Typography variant="h2" gutterBottom align="center" className="page-title">
        {t('humanitaire.title')}
      </Typography>

      {/* Introduction Section */}
      <Box className="introduction-section" textAlign="center">
        <Typography className="intro-title" variant="h4" gutterBottom>
          {t('humanitaire.introduction.title')}
        </Typography>
        <Typography variant="body1" className="description">
          <Trans i18nKey="humanitaire.introduction.description" components={{ strong: <strong /> }} />
        </Typography>
        <Box className="intro-image-container">
          <img src={convoi} alt={t('humanitaire.introduction.title')} className="intro-image" />
        </Box>
        <Typography variant="body1" className="description">
          <Trans i18nKey="humanitaire.introduction.description2" components={{ strong: <strong /> }} />
        </Typography>
      </Box>

      {/* Initiatives Section */}
      <Box className="section initiatives-section">
        <Typography variant="h4" gutterBottom align="center">
          {t('humanitaire.initiativesTitle')}
        </Typography>

        {/* Humanitarian Collection Section */}
        <Box className="description">
          <Box className="text-content">
            <Typography variant="h5" className="title" gutterBottom>
              {t('humanitaire.sections.humanitarianCollection.title')}
            </Typography>
            <Typography variant="body1" className="main-text">
              {t('humanitaire.sections.humanitarianCollection.description')}
            </Typography>
          </Box>
          <Box className="image-container">
            <img src={collectionImage} alt={t('humanitaire.sections.humanitarianCollection.title')} />
          </Box>
        </Box>

        {/* Liste collecte */}
        <Box className="description-inverse">
          <Box className="liste">
            <img src={liste} alt={t('humanitaire.sections.productsCollecte.title')} />
          </Box>
          <Box className="text-content">
            <Typography variant="h5" className="title" gutterBottom>
              {t('humanitaire.sections.productsCollecte.title')}
            </Typography>
            <ul className="collecte-list">
              <li className="list-item">
                <FontAwesomeIcon icon={faBriefcaseMedical} className="liste-icon" />
                <Typography variant="body1" className="list-text">
                  {t('humanitaire.sections.productsCollecte.besoin1')}
                </Typography>
              </li>
              <li className="list-item">
                <FontAwesomeIcon icon={faUtensils} className="liste-icon" />
                <Typography variant="body1" className="list-text">
                  {t('humanitaire.sections.productsCollecte.besoin2')}
                </Typography>
              </li>
              <li className="list-item">
                <FontAwesomeIcon icon={faSoap} className="liste-icon" />
                <Typography variant="body1" className="list-text">
                  {t('humanitaire.sections.productsCollecte.besoin3')}
                </Typography>
              </li>
              <li className="list-item">
                <FontAwesomeIcon icon={faBolt} className="liste-icon" />
                <Typography variant="body1" className="list-text">
                  {t('humanitaire.sections.productsCollecte.besoin4')}
                </Typography>
              </li>
              <li className="list-item">
                <FontAwesomeIcon icon={faHandsHelping} className="liste-icon" />
                <Typography variant="body1" className="list-text">
                  {t('humanitaire.sections.productsCollecte.donsfinancieres')}
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>

        {/* Collection Point Section */}
        <Box className="collection-points" py={5}>
          {/* Section Title */}
          <Typography variant="h4" gutterBottom align="center">
            {t('humanitaire.collectionPointsTitle')}
          </Typography>
          <Typography variant="h5" gutterBottom align="center">
            {t('humanitaire.collecteName')}
          </Typography>

          {/* Flexbox Layout for Map and Address */}
          <Box
            display="flex"
            flexDirection={{ xs: 'column', md: 'row' }} // Stack on small screens, row on medium+
            alignItems="center"
            justifyContent="center"
            mt={5}
          >
            {/* Map Container on the Left */}
            <Box
              className="map-container"
              flex="1"
              maxWidth={{ xs: '100%', md: '50%' }} // Full width on small, half width on medium+
              height={{ xs: '300px', md: '400px' }} // Set specific height for small screens to prevent collapse
              mb={{ xs: 4, md: 0 }} // Add margin below map on small screens
            >
              <MapContainer
                center={position}
                zoom={15}
                scrollWheelZoom={false}
                style={{ height: '400px', borderRadius: '10px' }}
              >
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <Marker position={position} icon={customIcon}>
                  <Popup>
                    {t('humanitaire.collecteName')} <br /> {t('humanitaire.adresse')}
                  </Popup>
                </Marker>
              </MapContainer>
            </Box>

            {/* Address Section on the Right */}
            <Box
              className="address-section"
              flex="1"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              px={4}
            >
              <Typography variant="h5" className="points-title" gutterBottom>
                {t('humanitaire.adresse')}
              </Typography>
              <Typography
                variant="h5"
                color="#f0c717"
                textTransform="uppercase"
                className="horaires-title"
                gutterBottom
              >
                {t('humanitaire.horairesTitle')}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {t('humanitaire.horaires')}
              </Typography>
              <Typography variant="h6" gutterBottom>
                {t('humanitaire.horaires2')}
              </Typography>

              <Typography variant="body1" mt={2}>
                {t('humanitaire.collecteDescritpiton')}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Special Projects Section */}
        <Box className="description-inverse">
          <Box className="image-container">
            <img src={specialProjectsImage} alt={t('humanitaire.sections.specialProjects.title')} />
          </Box>
          <Box className="text-content">
            <Typography variant="h5" className="title" gutterBottom>
              {t('humanitaire.sections.specialProjects.title')}
            </Typography>
            <Typography variant="body1" className="main-text">
              {t('humanitaire.sections.specialProjects.description')}
            </Typography>
            <Typography variant="body1" className="example-title">
              {t('humanitaire.sections.specialProjects.exampleTitle')}
            </Typography>
            <ul className="example-list">
              {(t('humanitaire.sections.specialProjects.examples', { returnObjects: true }) as string[]).map(
                (example, index) => (
                  <li key={index}>{example}</li>
                )
              )}
            </ul>
            <Typography variant="body1" className="main-text">
              {t('humanitaire.sections.specialProjects.continue')}
            </Typography>
          </Box>
        </Box>

        {/* Local Support Section */}
        <Box className="description">
          <Box className="text-content">
            <Typography variant="h5" className="title" gutterBottom>
              {t('humanitaire.sections.localSupport.title')}
            </Typography>
            <Typography variant="body1" className="main-text">
              {t('humanitaire.sections.localSupport.description')}
            </Typography>
          </Box>
          <Box className="image-container">
            <img src={localSupportImage} alt={t('humanitaire.sections.localSupport.title')} />
          </Box>
        </Box>
      </Box>

      {/* Fundraising Section */}
      <Box className="description-inverse">
        <Box className="image-container">
          <img src={aid} alt={t('humanitaire.sections.fundraising.title')} />
        </Box>
        <Box className="text-content">
          <Typography variant="h5" className="title" gutterBottom>
            {t('humanitaire.sections.fundraising.title')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('humanitaire.sections.fundraising.description')}
          </Typography>
        </Box>
      </Box>

      {/* Partnerships Section */}
      <Box className="description">
        <Box className="text-content">
          <Typography variant="h5" className="title" gutterBottom>
            {t('humanitaire.sections.partnerships.title')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('humanitaire.sections.partnerships.description')}
          </Typography>
        </Box>
        <Box className="image-container">
          <img src={childrenPresents} alt={t('humanitaire.sections.partnerships.title')} />
        </Box>
      </Box>

      {/* Future vision */}
      <Box className="description-inverse">
        <Box className="image-container">
          <img src={whyDon} alt={t('humanitaire.futureVision.title')} />
        </Box>
        <Box className="text-content">
          <Typography variant="h5" className="title" gutterBottom>
            {t('humanitaire.futureVision.title')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('humanitaire.futureVision.description')}
          </Typography>
          <Typography variant="body1" className="example-title">
            {t('humanitaire.futureVision.exampleTitle')}
          </Typography>
          <ul className="example-list">
            {(t('humanitaire.futureVision.examples', { returnObjects: true }) as string[]).map((example, index) => (
              <li key={index}>{example}</li>
            ))}
          </ul>
          <Typography variant="body1" className="main-text">
            {t('humanitaire.futureVision.continue')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('humanitaire.futureVision.continue2')}
          </Typography>
        </Box>
      </Box>

      {/* Ways to Help Section */}
      <Box className="ways-to-help-section">
        {/* Section Title */}
        <Typography variant="h4" textAlign="center" gutterBottom>
          {t('humanitaire.waysToHelpTitle')}
        </Typography>

        <Typography variant="h5" className="title" textAlign="center">
          <Trans i18nKey="humanitaire.communityImpact.title" components={{ strong: <strong /> }} />
        </Typography>

        {/* Grid Layout for Community Impact Statistics */}
        <Stack className="stats-section">
          {/* Use Trans component for each stat to render <strong> tags correctly */}
          {statistics.map((_, index) => (
            <Box key={index} className="info-box">
              <Box className="info-content">
                <Typography variant="body2">
                  <Trans
                    i18nKey={`humanitaire.communityImpact.statistics.${index}`}
                    components={{ strong: <strong /> }}
                  />
                </Typography>
              </Box>
            </Box>
          ))}
        </Stack>
      </Box>

      {/* Visual Separator */}
      <Box className="centered-image">
        <img className="img-in-the-middle" src={dotGroupSVG} alt="Visual Separator" />
      </Box>
      <Box className="padded-cards">
        <BenevoleCard />
        <DonateCard />
      </Box>

      <JoinUs />
      <BottomNav link1={SENSIBILISATION_LINK} link2={INCLUSION_LINK} />
    </Container>
  );
}
