import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import './PaddedCard.scss';

interface PaddedCardProps {
  image: string; // Image URL for the card
  title: string; // Title of the card
  description: string; // Description text
  link?: string; // Internal or external link for the card
  isExternal?: boolean; // Indicates if the link is an external one
}

export function PaddedCard({ image, title, description, link = '#', isExternal = false }: PaddedCardProps) {
  return (
    <div className="padded-card">
      <img src={image} alt={title} />
      <div className="content">
        <h4>{title}</h4>
        <span>{description}</span>
      </div>
      {isExternal ? (
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="learn-more-button btn btn-warning"
          aria-label={title}
        >
          <FontAwesomeIcon icon={faArrowRightLong} size="3x" />
        </a>
      ) : (
        <Link to={link} className="learn-more-button btn btn-warning">
          <FontAwesomeIcon icon={faArrowRightLong} size="3x" />
        </Link>
      )}
    </div>
  );
}
