import { useTranslation } from 'react-i18next';

import { CONTACT_US_LINK, KALYNANTES_LINK } from './App';
import workPNG from './assets/work-img.png';
import kalyNantesPNG from './assets/kalynantes.png';
import donateNG from './assets/don2.png';
import benevolePNG from './assets/benevole.png';
import { PaddedCard } from './components/PaddedCard';
import { EXTERNAL_LINKS } from './constants/links';

export function EmploymentCard() {
  const { t } = useTranslation();
  return (
    <PaddedCard
      image={workPNG}
      title={t('paddedComponents.employmentCard.title')}
      description={t('paddedComponents.employmentCard.description')}
      link={CONTACT_US_LINK}
    />
  );
}

export function DonateCard() {
  const { t } = useTranslation();
  return (
    <PaddedCard
      image={donateNG}
      title={t('paddedComponents.donateCard.title')}
      description={t('paddedComponents.donateCard.description')}
      link={EXTERNAL_LINKS.DONATION_LINK}
      isExternal
    />
  );
}

export function KalynaCard() {
  const { t } = useTranslation();
  return (
    <PaddedCard
      image={kalyNantesPNG}
      title={t('paddedComponents.kalynaCard.title')}
      description={t('paddedComponents.kalynaCard.description')}
      link={KALYNANTES_LINK}
    />
  );
}

export function BenevoleCard() {
  const { t } = useTranslation();
  return (
    <PaddedCard
      image={benevolePNG}
      title={t('paddedComponents.benevoleCard.title')}
      description={t('paddedComponents.benevoleCard.description')}
      link={CONTACT_US_LINK}
    />
  );
}
