import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';

import dotGroupSVG from './assets/dot-group.svg';
import './JoinUs.scss';
import { EXTERNAL_LINKS } from './constants/links';

export function JoinUs() {
  const { t } = useTranslation();
  return (
    <div className="volya-join-us-container">
      <img className="img-in-the-middle" src={dotGroupSVG} />
      <img className="join-us" src="join-us.png" />
      <div className="main">
        <h2>{t('joinUs.title')}</h2>
        <p>{t('joinUs.description')}</p>
        <Button
          variant="light"
          className="CTA"
          href={EXTERNAL_LINKS.VOLUNTEER_LINK}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('joinUs.becomeVolunteer')}
        </Button>
      </div>
      <img className="img-in-the-middle" src={dotGroupSVG} />
    </div>
  );
}
