import { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

// Component Imports
import { JoinUs } from './JoinUs';
import { BenevoleCard, DonateCard, EmploymentCard, KalynaCard } from './PaddedComponents';
import { VolyaCard } from './components/VolyaCard';
import { PartnersVolya } from './components/PartnersVolya';
// Asset Imports
import dotGroupSVG from './assets/dot-group.svg';
import aideHumanPNG from './assets/our-goals/collecte-laide-humanitaire.jpg';
import inclusionPNG from './assets/our-goals/inclusion-apporte-laide-deplaces.jpg';
import sensibilisationPNG from './assets/our-goals/sensibilisation-communication.jpg';
// Fixture Imports
// import { events } from './fixtures';
// Link Imports
import {
  ABOUT_US_LINK,
  CONTACT_US_LINK,
  // EVENTS_LINK,
  HUMANITAIRE_LINK,
  INCLUSION_LINK,
  SENSIBILISATION_LINK,
} from './App';

import './Home.scss'; // Style import should be the last

// const useEvents = () => {
//   return events;
// };

export function Home() {
  const { t } = useTranslation(['translation', 'fixtures']);
  const navigate = useNavigate();
  // const events = [...useEvents()].splice(0, 4);
  // const allEvents = useEvents() || 0;
  // const events = [...allEvents].splice(0, 3);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="home-new">
      {/* Banner Section */}
      <div className="banner-container">
        <h1>{t('home.association')}</h1>
        <p>{t('home.banner')}</p>
        <Button size="lg">
          <Link to={CONTACT_US_LINK} style={{ color: 'white', textTransform: 'uppercase', textDecoration: 'none' }}>
            {t('home.contactUs')}
          </Link>
        </Button>
      </div>
      {/* Goals Section */}
      <div className="goal-photos-container">
        <VolyaCard image={aideHumanPNG} title={t('home.collecte')} link={HUMANITAIRE_LINK} />
        <VolyaCard image={inclusionPNG} title={t('home.inclusion')} link={INCLUSION_LINK} />
        <VolyaCard image={sensibilisationPNG} title={t('home.sensibilisation')} link={SENSIBILISATION_LINK} />
      </div>
      {/* About VOLYA Section */}
      <div className="volya-about-us-container">
        <img src={dotGroupSVG} />
        <div className="dummy" />
        <div className="main">
          <h2>{t('home.association')}</h2>
          <p>{t('home.about')}</p>
          <Button variant="warning" onClick={() => navigate(ABOUT_US_LINK)}>
            {t('home.learnMore')}
          </Button>
        </div>
      </div>

      {/* Partner Section */}
      <PartnersVolya />

      <img className="img-in-the-middle" src={dotGroupSVG} />
      <div className="padded-cards">
        <KalynaCard />
        <EmploymentCard />
        <BenevoleCard />
        <DonateCard />
      </div>

      {/* <img className="img-in-the-middle" src={dotGroupSVG} />
      <div className="events-container">
        <h2>{t('home.eventsTitle')}</h2>
        <ul>
          {events.map((e) => (
            <li key={e.nameKey}>
              <Card>
                <Card.Img src={`${process.env.PUBLIC_URL}/events/${e.img}`} alt={t(e.nameKey, { ns: 'fixtures' })} />
                <Card.Body>
                  <Card.Text>{t(e.descriptionKey, { ns: 'fixtures' })}</Card.Text>
                </Card.Body>
              </Card>
            </li>
          ))}
        </ul>{' '}
        <Link to={EVENTS_LINK} className="transparent-CTA-btn btn">
          <Trans count={allEvents.length}>{t('home.viewAllEvents', { count: allEvents.length })}</Trans>
        </Link>
      </div> */}

      <JoinUs />
    </div>
  );
}
