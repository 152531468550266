import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { Box, Container, Tooltip, Typography, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faPaintBrush, faLandmark, faGlobeEurope, faUserFriends } from '@fortawesome/free-solid-svg-icons';

import { JoinUs } from './JoinUs';
import kalyNantes from './assets/kalynantes.png';
import calendarKaly from './assets/calendar-kalynantes.png';
import kimnata from './assets/kaly_kimnata.png';
import dotGroupSVG from './assets/dot-group.svg';
import { ABOUT_US_LINK, HOME_LINK } from './App';
import { BottomNav } from './FactPages/FactPagesNav';
import { partnersKaly, tutors, tutorsAvatars } from './fixtures';
import './Kalynantes.scss';
import { EXTERNAL_LINKS, EXTERNAL_LINKS_PARTNERS } from './constants/links';
import { ExternalLink } from './components/ExternalLinksProps';

const subjectIcons = {
  languageLiterature: faBook,
  artTherapy: faPaintBrush,
  historyCulture: faLandmark,
  euroIntegration: faGlobeEurope,
  activitiesExcursions: faUserFriends,
};

export function Kalynantes() {
  const { t, i18n } = useTranslation(['translation', 'fixtures']);
  const getPhoto = (e, i) => {
    return tutorsAvatars[i] ? (
      <img
        src={`${process.env.PUBLIC_URL}/avatars/${tutorsAvatars[i]}.png`}
        alt={t(e.nameKey, { ns: 'fixtures' })}
        className="avatar-image"
      />
    ) : (
      <img src="" alt="tutor" />
    );
  };

  return (
    <div className="kalynantes">
      <Container>
        {/* Title Section */}
        <Typography variant="h2" gutterBottom align="center" className="page-title">
          {t('kalynantes.title')}
        </Typography>

        {/* Description Section */}
        <Box className="description">
          <Box className="text-content">
            <Typography variant="h4" className="subtitle" gutterBottom>
              {t('kalynantes.subtitle')}
            </Typography>
            <Typography variant="body1" className="main-text">
              {t('kalynantes.description')}
            </Typography>
          </Box>
          <Box className="image-container">
            <img src={kalyNantes} alt={t('Ecole Kalynantes')} />
          </Box>
        </Box>

        {/* Mission Section */}
        <Box className="section" textAlign="center">
          <Typography variant="h4">{t('kalynantes.missionTitle')}</Typography>
          <Typography variant="body1">{t('kalynantes.missionDescription')}</Typography>
        </Box>

        {/* Program Section */}
        <Box className="section" textAlign="center">
          <Typography variant="h4">{t('kalynantes.programTitle')}</Typography>
          <Typography variant="body1">{t('kalynantes.programDescription')}</Typography>
        </Box>
        <Box className="calendar-image">
          <img src={calendarKaly} alt={t('Calendrier année 2024-2025')} />
        </Box>

        {/* Subjects Section */}
        <Box className="section">
          <Typography variant="h6" gutterBottom>
            {t('kalynantes.mainSubjects')}
          </Typography>
          <ul className="subject-list">
            {Object.keys(t('kalynantes.subjects', { returnObjects: true })).map((subjectKey) => (
              <li key={subjectKey} className="subject-item">
                <FontAwesomeIcon icon={subjectIcons[subjectKey]} className="subject-icon" />
                <Typography variant="body1" className="subject-text">
                  {t(`kalynantes.subjects.${subjectKey}`)}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>

        {/* Visual Separator */}
        <Box className="centered-image">
          <img className="img-in-the-middle" src={kimnata} alt="Visual Separator" />
        </Box>
        {/* Age Groups Section */}
        <div className="age-groups-container">
          {/* <div className="main"> */}
          <Box className="section" textAlign="center">
            <Typography variant="h4">{t('kalynantes.groupsTitle')}</Typography>
            <Typography variant="body1" gutterBottom>
              {t('kalynantes.groupsDescription')}
            </Typography>

            {/* Responsive Stack Layout for Age Groups */}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={4} justifyContent="center" alignItems="center">
              {['sonyashnyky', 'kozachata', 'sokolyata', 'leleky'].map((group, index) => (
                <Box
                  key={group}
                  className={`age-group-card ${index === 0 ? 'pattern-bottom-left' : ''} ${
                    index === 3 ? 'pattern-top-right' : ''
                  }`}
                  p={3}
                  boxShadow={3}
                  borderRadius="15px"
                >
                  {/* Display Group Title */}
                  <Typography variant="h6" className="group-title">
                    {t(`kalynantes.ageGroups.${group}`)}
                  </Typography>
                  {/* Description Based on the Corresponding Age Range */}
                  <Typography variant="body1" className="group-description">
                    {t(
                      `kalynantes.ageGroups.${
                        group === 'sonyashnyky'
                          ? '4-7'
                          : group === 'kozachata'
                            ? '7-10'
                            : group === 'sokolyata'
                              ? '11-13'
                              : '14-17'
                      }`
                    )}
                  </Typography>
                </Box>
              ))}
            </Stack>
            <Typography variant="body1">{t('kalynantes.maxChildren')}</Typography>
          </Box>
        </div>
        {/* Visual Separator */}
        <Box className="centered-image">
          <img className="img-in-the-middle" src={dotGroupSVG} alt="Visual Separator" />
        </Box>
        {/* Partners Section */}
        <Box className="team-section" textAlign="center">
          <Typography variant="h4" align="center">
            {t('kalynantes.partnersTitle')}
          </Typography>
          <Stack className="team-list">
            {partnersKaly.map((partner) => (
              <Box key={partner.nameKey} className="team-member">
                <Tooltip title={t(partner.nameKey, { ns: 'fixtures' })} arrow>
                  <ExternalLink href={EXTERNAL_LINKS_PARTNERS[partner.nameKey]}>
                    <img
                      src={`${process.env.PUBLIC_URL}/partners/${partner.img}`}
                      alt={t(partner.nameKey, { ns: 'fixtures' })}
                      className="avatar-image"
                    />
                  </ExternalLink>
                </Tooltip>
                <Typography variant="h6" className="member-name">
                  {t(partner.nameKey, { ns: 'fixtures' })}
                </Typography>
                <Typography variant="body2">{t(partner.descriptionKey, { ns: 'fixtures' })}</Typography>
              </Box>
            ))}
          </Stack>
        </Box>

        {/* Tutors Section */}
        <Box className="team-section">
          <Typography variant="h4" align="center" className="team-title">
            {t('kalynantes.tutorsTitle')}
          </Typography>
          <Typography variant="body1" align="center" className="team-description">
            {t('kalynantes.tutorsDescription')}
          </Typography>
          <Stack className="team-list">
            {tutors[i18n.language].map((e, i) => (
              <Box key={e.nameKey} className="team-member">
                {getPhoto(e, i)}
                <Typography variant="h6" className="member-name">
                  {t(e.nameKey, { ns: 'fixtures' })}
                </Typography>
                <Typography variant="body2">{t(e.descriptionKey, { ns: 'fixtures' })}</Typography>
              </Box>
            ))}
          </Stack>
        </Box>

        {/* Join Us Section */}
        <Box className="section" textAlign="center">
          <Typography variant="h4">{t('kalynantes.joinUsTitle')}</Typography>
          <Typography variant="body1">{t('kalynantes.joinUsDescription')}</Typography>
          <Button
            variant="warning"
            target="_blank"
            rel="noopener noreferrer"
            href={EXTERNAL_LINKS.KALYNANTES_INSCRIPTION}
          >
            {t('kalynantes.registerButton')}
          </Button>
        </Box>

        {/* Contact Section */}
        <Box className="section" textAlign="center">
          <Typography variant="body1">{t('kalynantes.infosTitle')}</Typography>
          <Typography variant="h5" color="#f0c717">
            {t('kalynantes.infosEmail')}
          </Typography>
        </Box>

        {/* Collaboration Section */}
        <Box className="section" textAlign="center">
          <Typography variant="h4" className="team-title">
            {t('kalynantes.collaborationTitle')}
          </Typography>
          <Typography variant="body1">{t('kalynantes.collaborationDescription')}</Typography>
        </Box>

        <JoinUs />

        <BottomNav link1={ABOUT_US_LINK} link2={HOME_LINK} />
      </Container>
    </div>
  );
}
