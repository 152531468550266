import { Trans, useTranslation } from 'react-i18next';

import { Container, Typography, Box } from '@mui/material';
import './Sensibilisation.scss';

// Image Imports
import sensibilisation from '../assets/our-goals/24-24.png';
import culturalDiplomacy from '../assets/our-goals/mict-cosmopolis.png';
import manifImage from '../assets/our-goals/place-royale.jpg';
import concertsImage from '../assets/our-goals/alona-cosmo.jpeg';
import interactiveEventsImage from '../assets/our-goals/yebles.png';
import schoolImage from '../assets/our-goals/cosmopolis-schedryk.png';
import communityImage from '../assets/our-goals/ukrainian.png';
import beginning from '../assets/our-goals/24.png';
import futureVisionImage from '../assets/our-goals/activity-volya.png';
import dotGroupSVG from '../assets/dot-group.svg';
import { BenevoleCard, DonateCard } from '../PaddedComponents';
import { JoinUs } from '../JoinUs';
import { BottomNav } from './FactPagesNav';
import { HUMANITAIRE_LINK, INCLUSION_LINK } from '../App';

export function Sensibilisation() {
  const { t } = useTranslation();
  return (
    <Container className="sensibilisation-page">
      {/* Page Title */}
      <Typography variant="h2" gutterBottom className="page-title">
        {t('sensibilisation.title')}
      </Typography>

      {/* Introduction Section */}
      <Box className="introduction-section">
        <Typography className="intro-title" variant="h4" textAlign="center" gutterBottom>
          {t('sensibilisation.introduction.title')}
        </Typography>
        <Typography variant="body1" className="description">
          <Trans i18nKey="sensibilisation.introduction.description" components={{ strong: <strong /> }} />
        </Typography>
        <Box className="intro-image-container">
          <img src={sensibilisation} alt={t('inclusion.heroesOfAide')} className="intro-image" />
        </Box>
      </Box>

      {/* Initiatives Section */}
      <Box className="section initiatives-section">
        <Typography variant="h4" gutterBottom align="center">
          {t('sensibilisation.initiativesTitle')}
        </Typography>
        <Box className="description">
          <Box className="text-content">
            <Typography variant="h5" className="title" gutterBottom>
              {t('sensibilisation.sections.beginning.title')}
            </Typography>
            {/* check the json, doesn't work  */}
            <Typography variant="body1" className="subtitle">
              {t('sensibilisation.sections.beginning.subtitle')}
            </Typography>
            <Typography variant="body1" className="main-text">
              {t('sensibilisation.sections.beginning.description')}
            </Typography>
            {/* <Typography variant="body1" className="main-text">
              {t('sensibilisation.sections.beginning.extendedDescription')}
            </Typography> */}
          </Box>
          <Box className="image-container">
            <img src={beginning} alt={t('sensibilisation')} />
          </Box>
        </Box>
        {/* culturalDiplomacy */}
        <Box className="description-inverse">
          <Box className="image-container">
            <img src={culturalDiplomacy} alt={t('sensibilisation')} />
          </Box>
          <Box className="text-content">
            <Typography variant="h5" className="title" gutterBottom>
              {t('sensibilisation.sections.culturalDiplomacy.title')}
            </Typography>
            <Typography variant="body1" className="subtitle">
              {t('sensibilisation.sections.culturalDiplomacy.subtitle')}
            </Typography>
            <Typography variant="body1" className="main-text">
              {t('sensibilisation.sections.culturalDiplomacy.description')}
            </Typography>
            <Typography variant="body1" className="example-title">
              {t('sensibilisation.sections.culturalDiplomacy.exampleTitle')}
            </Typography>
            <ul className="example-list">
              {(t('sensibilisation.sections.culturalDiplomacy.examples', { returnObjects: true }) as string[]).map(
                (example, index) => (
                  <li key={index}>{example}</li>
                )
              )}
            </ul>
          </Box>
        </Box>
        {/* publicActionsImage */}
        <Box className="description">
          <Box className="text-content">
            <Typography variant="h5" className="title" gutterBottom>
              {t('sensibilisation.sections.publicActions.title')}
            </Typography>
            <Typography variant="body1" className="subtitle">
              {t('sensibilisation.sections.publicActions.subtitle')}
            </Typography>
            <Typography variant="body1" className="main-text">
              {t('sensibilisation.sections.publicActions.description')}
            </Typography>
            <Typography variant="body1" className="example-title">
              {t('sensibilisation.sections.publicActions.exampleTitle')}
            </Typography>
            <ul className="example-list">
              {(t('sensibilisation.sections.publicActions.examples', { returnObjects: true }) as string[]).map(
                (example, index) => (
                  <li key={index}>{example}</li>
                )
              )}
            </ul>
          </Box>
          <Box className="image-container">
            <img src={manifImage} alt={t('sensibilisation')} />
          </Box>
        </Box>

        {/* concertsImage */}
        <Box className="description-inverse">
          <Box className="image-container">
            <img src={concertsImage} alt={t('sensibilisation')} />
          </Box>
          <Box className="text-content">
            <Typography variant="h5" className="subttitleitle" gutterBottom>
              {t('sensibilisation.sections.charityConcerts.title')}
            </Typography>
            <Typography variant="body1" className="subtitle">
              {t('sensibilisation.sections.charityConcerts.subtitle')}
            </Typography>
            <Typography variant="body1" className="main-text">
              {t('sensibilisation.sections.charityConcerts.description')}
            </Typography>
            <Typography variant="body1" className="example-title">
              {t('sensibilisation.sections.charityConcerts.exampleTitle')}
            </Typography>
            <ul className="example-list">
              {(t('sensibilisation.sections.charityConcerts.examples', { returnObjects: true }) as string[]).map(
                (example, index) => (
                  <li key={index}>{example}</li>
                )
              )}
            </ul>
          </Box>
        </Box>

        {/* interactiveEventsImage */}
        <Box className="description">
          <Box className="text-content">
            <Typography variant="h5" className="title" gutterBottom>
              {t('sensibilisation.sections.interactiveEvents.title')}
            </Typography>
            <Typography variant="body1" className="subtitle">
              {t('sensibilisation.sections.interactiveEvents.subtitle')}
            </Typography>
            <Typography variant="body1" className="main-text">
              {t('sensibilisation.sections.interactiveEvents.description')}
            </Typography>
            <Typography variant="body1" className="example-title">
              {t('sensibilisation.sections.interactiveEvents.exampleTitle')}
            </Typography>
            <ul className="example-list">
              {(t('sensibilisation.sections.interactiveEvents.examples', { returnObjects: true }) as string[]).map(
                (example, index) => (
                  <li key={index}>{example}</li>
                )
              )}
            </ul>
          </Box>
          <Box className="image-container">
            <img src={interactiveEventsImage} alt={t('sensibilisation')} />
          </Box>
        </Box>

        {/* schoolImage */}
        <Box className="description-inverse">
          <Box className="image-container">
            <img src={schoolImage} alt={t('sensibilisation')} />
          </Box>
          <Box className="text-content">
            <Typography variant="h5" className="title" gutterBottom>
              {t('sensibilisation.sections.schoolAwareness.title')}
            </Typography>
            <Typography variant="body1" className="subtitle">
              {t('sensibilisation.sections.schoolAwareness.subtitle')}
            </Typography>
            <Typography variant="body1" className="main-text">
              {t('sensibilisation.sections.schoolAwareness.description')}
            </Typography>
            <Typography variant="body1" className="example-title">
              {t('sensibilisation.sections.schoolAwareness.exampleTitle')}
            </Typography>
            <ul className="example-list">
              {(t('sensibilisation.sections.schoolAwareness.examples', { returnObjects: true }) as string[]).map(
                (example, index) => (
                  <li key={index}>{example}</li>
                )
              )}
            </ul>
          </Box>
        </Box>
      </Box>

      {/* Community Involvement Section */}
      <Box className="description">
        <Box className="text-content">
          <Typography variant="h5" className="title" gutterBottom>
            {t('sensibilisation.communityInvolvement.title')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('sensibilisation.communityInvolvement.description')}
          </Typography>
        </Box>
        <Box className="image-container">
          <img src={communityImage} alt={t('sensibilisation.communityInvolvement.title')} />
        </Box>
      </Box>

      {/* Future Vision Section */}
      <Box className="description-inverse">
        <Box className="image-container">
          <img src={futureVisionImage} alt={t('sensibilisation.futureVision.title')} />
        </Box>
        <Box className="text-content">
          <Typography variant="h5" className="title" gutterBottom>
            {t('sensibilisation.futureVision.title')}
          </Typography>
          <Typography variant="body1" className="main-text">
            {t('sensibilisation.futureVision.description')}
          </Typography>
        </Box>
      </Box>

      {/* Visual Separator */}
      <Box className="centered-image">
        <img className="img-in-the-middle" src={dotGroupSVG} alt="Visual Separator" />
      </Box>

      {/* Cards Section */}
      <Box className="padded-cards">
        <BenevoleCard />
        <DonateCard />
      </Box>

      <JoinUs />
      <BottomNav link1={INCLUSION_LINK} link2={HUMANITAIRE_LINK} />
    </Container>
  );
}
