import { Route, Routes } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import { Navigation } from './Navigation';
import { Home } from './Home';
import { Events } from './Events';
import { Footer } from './Footer';
import { FaireDon } from './FaireDon';
import { Inclusion } from './FactPages/Inclusion';
import { Sensibilisation } from './FactPages/Sensibilisation';
import { AboutUs } from './AboutUs';
import { Kalynantes } from './Kalynantes';
import './App.scss';
import './i18n';
import { ScrollToTop } from './utils/ScrollToTop';
import { AideHumanitaire } from './FactPages/AideHumanitaire';
import { ContactUs } from './ContactUs';

export const HOME_LINK = `${process.env.PUBLIC_URL}/`;
export const EVENTS_LINK = `${process.env.PUBLIC_URL}/evenements`;
export const ABOUT_US_LINK = `${process.env.PUBLIC_URL}/notre-mission`;
export const KALYNANTES_LINK = `${process.env.PUBLIC_URL}/kalynantes-ecole`;
export const CONTACT_US_LINK = `${process.env.PUBLIC_URL}/contact`;
export const FAIRE_DON_LINK = `${process.env.PUBLIC_URL}/faire-don`;
export const SENSIBILISATION_LINK = `${process.env.PUBLIC_URL}/sensibilisation`;
export const INCLUSION_LINK = `${process.env.PUBLIC_URL}/inclusion`;
export const HUMANITAIRE_LINK = `${process.env.PUBLIC_URL}/aide-humanitaire`;

function App() {
  return (
    <div className="App">
      <div className="top-background-container">
        <div />
      </div>
      <Navigation />

      <Container className="body-container">
        <ScrollToTop />
        <Routes>
          <Route path={HOME_LINK} element={<Home />} />
          <Route path={EVENTS_LINK} element={<Events />} />
          <Route path={HUMANITAIRE_LINK} element={<AideHumanitaire />} />
          <Route path={INCLUSION_LINK} element={<Inclusion />} />
          <Route path={SENSIBILISATION_LINK} element={<Sensibilisation />} />
          <Route path={ABOUT_US_LINK} element={<AboutUs />} />
          <Route path={KALYNANTES_LINK} element={<Kalynantes />} />
          <Route path={FAIRE_DON_LINK} element={<FaireDon />} />
          <Route path={CONTACT_US_LINK} element={<ContactUs />} />
        </Routes>
      </Container>

      <Footer />
    </div>
  );
}

export default App;
