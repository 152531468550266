import { Container, Image } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faSquareFacebook, faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import {
  ABOUT_US_LINK,
  HUMANITAIRE_LINK,
  // CONTACT_US_LINK,
  // EVENTS_LINK,
  FAIRE_DON_LINK,
  HOME_LINK,
  INCLUSION_LINK,
  SENSIBILISATION_LINK,
} from './App';
import { ExternalLink } from './components/ExternalLinksProps';
import { EXTERNAL_LINKS } from './constants/links';

export function Footer() {
  const { t } = useTranslation();
  return (
    <div className="footer-container">
      <Container>
        <div className="footer-content">
          <div className="slogan">
            <Image src={`${process.env.PUBLIC_URL}/logo_volya.png`} />
            <p>{t('footer.slogan', { context: 'footer' })}</p>
          </div>

          <div className="socials">
            <ExternalLink href={EXTERNAL_LINKS.INSTAGRAM}>
              <FontAwesomeIcon icon={faInstagram} />
            </ExternalLink>
            <ExternalLink href={EXTERNAL_LINKS.LINKEDIN}>
              <FontAwesomeIcon icon={faLinkedin} />
            </ExternalLink>
            <ExternalLink href={EXTERNAL_LINKS.FACEBOOK}>
              <FontAwesomeIcon icon={faSquareFacebook} />
            </ExternalLink>
            <ExternalLink href={EXTERNAL_LINKS.TWITTER}>
              <FontAwesomeIcon icon={faTwitter} />
            </ExternalLink>
            <NavLink className="btn btn-primary" to={FAIRE_DON_LINK}>
              {t('footer.faireDon')}
            </NavLink>
          </div>

          <div className="link-categories">
            <div className="link-groups">
              <h6>{t('footer.aPropos', { context: 'footer' })}</h6>
              <ul>
                <li>
                  <Link to={HOME_LINK}>{t('footer.home', { context: 'footer' })}</Link>
                </li>
                <li className="disabled-font">
                  {t('footer.events', { context: 'footer' })}
                  {/* <Link to={EVENTS_LINK}></Link> */}
                </li>
                <li>
                  <Link to={HUMANITAIRE_LINK}>{t('footer.humanitaire', { context: 'footer' })}</Link>
                </li>
                <li>
                  <Link to={INCLUSION_LINK}>{t('footer.inclusion', { context: 'footer' })}</Link>
                </li>
                <li>
                  <Link to={SENSIBILISATION_LINK}>{t('footer.sensibilisation', { context: 'footer' })}</Link>
                </li>
                <li>
                  <Link to={ABOUT_US_LINK}>{t('footer.mission', { context: 'footer' })}</Link>
                </li>
                <li>
                  <Link to={FAIRE_DON_LINK}>{t('footer.faireDonLink', { context: 'footer' })}</Link>
                </li>
              </ul>
            </div>
            <div className="link-groups">
              <h6 className="disabled-font">{t('footer.refugies', { context: 'footer' })}</h6>
              <ul>
                <li className="disabled-font">{t('footer.liensUtiles', { context: 'footer' })}</li>
                <li className="disabled-font">{t('footer.coursFLE', { context: 'footer' })}</li>
                <li className="disabled-font">{t('footer.travail', { context: 'footer' })}</li>
              </ul>
            </div>
            <div className="link-groups">
              <h6 className="disabled-font">{t('footer.nousRejoindre', { context: 'footer' })}</h6>
              <ul>
                <li className="disabled-font">{t('footer.benevoles', { context: 'footer' })}</li>
                <li className="disabled-font">{t('footer.partenaires', { context: 'footer' })}</li>
              </ul>
            </div>
            <div className="link-groups">
              <h6>{t('footer.contactUs', { context: 'footer' })}</h6>
              <ul>
                <li className="contacts">
                  <a href="mailto:ukraine@volya-asso.fr">{t('footer.mail', { context: 'footer' })}</a>
                </li>
                <li>{t('footer.address', { context: 'footer' })}</li>
              </ul>
            </div>
          </div>
          {/* Copyright Section */}
          <div className="copyright">
            <p>&copy; {new Date().getFullYear()} Association Volya. All rights reserved.</p>
          </div>
        </div>
      </Container>
    </div>
  );
}
