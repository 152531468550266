import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

import './FactPagesNav.scss';

export function BottomNav({ link1, link2 }) {
  return (
    <nav className="fact-pages-nav">
      <ul>
        <li>
          <Link to={link1} className="learn-more-button btn btn-warning">
            <FontAwesomeIcon icon={faArrowLeft} size="3x" />
            <i className="fa-solid fa-left-long" />
          </Link>
        </li>
        <li>
          <Link to={link2} className="learn-more-button btn btn-warning">
            <FontAwesomeIcon icon={faArrowRight} size="3x" />
            <i className="fa-solid fa-right-long" />
          </Link>
        </li>
      </ul>
    </nav>
  );
}
