export const EXTERNAL_LINKS = {
  DONATION_LINK: 'https://www.helloasso.com/associations/association-franco-ukrainienne-volya/formulaires/1',
  KALYNANTES_INSCRIPTION: 'https://forms.gle/m1gSk3mruukCRLvE6',
  VOLUNTEER_LINK: 'https://forms.gle/T8v9tUCWc7obX3ud9',
  FACEBOOK: 'https://www.facebook.com/VolyaAsso',
  INSTAGRAM: 'https://www.instagram.com/volya_asso/',
  TWITTER: 'https://x.com/volya_asso',
  LINKEDIN: 'https://fr.linkedin.com/company/association-franco-ukrainienne-volya',
  LINKTREE: 'https://linktr.ee/volya_asso',
};

export const EXTERNAL_LINKS_PARTNERS = {
  PLACEHOLDER_LINK: 'placeholder',
};
